import { ref, Ref, watch } from 'vue';
import { TvDataSearchApi } from '@/api';
import { TvDataSearchCmCreative } from '@/api/openapi';
import useLoading from '@/composables/loading';
import { formatIds, FormValue } from '@/composables/datasearch/cmrf/inputform';

import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import { requestCanceler } from '@/plugins/axiosCancel';

import { DateRange } from '@/components/ui/DatePicker.vue';

import { formatDuration } from '../../../duration';

interface CmCreativeDataListItem extends TvDataSearchCmCreative {
  select: number;
}
// CM素材を選択
export interface CmCreative {
  cmCreativeDataListItems: Ref<CmCreativeDataListItem[]>;
  isLoadingGetCmCreatives: Ref<boolean>;
  notIncludedListIds: Ref<number[]>;
  isCmCreativeOptionsOpen: Ref<boolean>;
}
export const useCmCreative = ({
  form,
  durationValue,
  cmCreativeValue
}: {
  form: FormValue;
  durationValue: Ref<DateRange | undefined>;
  cmCreativeValue: Ref<number[]>;
}): CmCreative => {
  const { cmProductsValue, cmSponsorsValue } = form;
  const cmCreativeDataListItems = ref([] as CmCreativeDataListItem[]);
  const selectedCmCreativeDataListItems = ref([] as CmCreativeDataListItem[]);
  const notIncludedListIds = ref([] as number[]);
  const isCmCreativeOptionsOpen = ref(false);

  // CM素材取得
  const [isLoadingGetCmCreatives, fetch] = useLoading(_fetch);

  watch([durationValue, cmProductsValue, cmSponsorsValue], () => {
    // APIのキャンセル
    requestCanceler.cancel();
    getCmCreatives();
  });

  // CM素材をOFFにしたらcmCreativeValueを空にする
  watch(isCmCreativeOptionsOpen, v => {
    if (!v) {
      cmCreativeValue.value = [];
    }
  });

  const getCmCreatives = async () => {
    if (cmProductsValue && cmProductsValue.value.length === 0) {
      cmCreativeDataListItems.value = [];
      cmCreativeValue.value = [];
      selectedCmCreativeDataListItems.value = [];
      return;
    }
    if (cmCreativeValue.value.length > 0) {
      // 選択状態のリスト
      selectedCmCreativeDataListItems.value = cmCreativeDataListItems.value.filter(
        v => cmCreativeValue.value.includes(v.id)
      );
    }
    const res = await fetch({ form });
    cmCreativeDataListItems.value = res.map(v => ({
      ...v,
      select: v.id
    }));
    // 前のCM素材取得処理後に選択状態であったが最新のCM素材取得処理レスポンスの一覧には含まれてないリスト
    // (画面で赤字表示するリスト)
    const notIncludedList = selectedCmCreativeDataListItems.value.filter(
      item => !res.map(data => data.id).includes(item.id)
    );
    notIncludedListIds.value = notIncludedList.map(v => v.id);
    cmCreativeDataListItems.value = cmCreativeDataListItems.value
      .concat(notIncludedList)
      .sort((a, b) => a.id - b.id);
  };

  return {
    cmCreativeDataListItems,
    isLoadingGetCmCreatives,
    notIncludedListIds,
    isCmCreativeOptionsOpen
  };
};

const _fetch = async ({ form }): Promise<TvDataSearchCmCreative[]> => {
  const { companyId, durationValue, cmProductsValue } = form;
  const [productsIds] = formatIds([{ items: cmProductsValue, key: 'id' }]);

  const [startDate, endDate] = formatDuration(durationValue);

  try {
    const {
      data
    } = await TvDataSearchApi.getTvdataSearchCmCreativesSearch(
      startDate,
      endDate,
      productsIds.value,
      companyId.value,
      { cancelToken: requestCanceler.token() }
    );
    return data;
  } catch (e) {
    console.log(e);
    if (e.state === httpCode.forbidden) {
      toast({
        title: 'CM素材データの取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    return [] as TvDataSearchCmCreative[];
  }
};
