import { ref, Ref } from 'vue';
import { TvDataSearchApi } from '@/api';
import { FrequencyDistribution } from '@/api/openapi';
import { PropsTableData } from '@/composables/datasearch/cmrf/inputform';
import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import useLoading from '@/composables/loading';

interface CmrfGraph {
  isLoading: Ref<boolean>;
  isTimeOut: Ref<boolean>;
  data: Ref<FrequencyDistribution[]>;
  reload: (fqThresholds?: number[]) => void;
}

interface PropsFqData extends PropsTableData {
  fqThresholds?: number[] | undefined;
}

export const CHART_OPTIONS = {
  chart: {
    marginTop: 28,
    type: 'column',
    scrollablePlotArea: {
      scrollPositionX: 0
    }
  },
  xAxis: {
    tickWidth: 0,
    labels: {
      autoRotation: false,
      style: {
        textOverflow: 'none'
      },
      useHTML: true
    }
  },
  yAxis: {
    tickInterval: 25,
    max: 100,
    min: 0,
    gridLineWidth: 0.5,
    title: {
      text: '(%)',
      align: 'high',
      offset: 0,
      rotation: 0,
      y: -16,
      x: -16
    },
    labels: {
      formatter: function(): number | undefined {
        const chart = (this as unknown) as {
          pos: number;
        };
        if (chart.pos === 0 || chart.pos === 50 || chart.pos === 100) {
          return chart.pos;
        }
      }
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          opacity: 1
        }
      }
    },
    column: {
      maxPointWidth: 150,
      pointPadding: 0,
      groupPadding: 0.08,
      // enableMouseTracking: false,
      stacking: 'normal',
      dataLabels: {
        enabled: true
      }
    }
  },
  title: false,
  credits: {
    enabled: false
  },
  exporting: {
    enabled: true,
    buttons: false,
    allowHTML: true,
    chartOptions: {
      chart: {
        marginTop: 50,
        spacingLeft: 15,
        spacingBottom: 30
      },
      title: {
        style: {
          fontSize: '12px'
        }
      },
      credits: {
        enabled: true,
        text: '© TVAL Switch Media, Inc.'
      },
      xAxis: {
        tickWidth: 0,
        max: null
      }
    }
  }
};

export const HEADERS = [
  '',
  'リーチ人数(万人)',
  'リーチ率(%)',
  'GRP',
  '平均FQ(回)'
];

export const CHART_COLORS = [
  { bg: '#eceeee', text: '#6b90df' },
  { bg: '#e5f6ff', text: '#6b90df' },
  { bg: '#c0e4ff', text: '#6b90df' },
  { bg: '#74beff', text: '#ffffff' },
  { bg: '#6b90df', text: '#ffffff' }
];

export const useCmrfGraph = (form: PropsTableData): CmrfGraph => {
  const data = ref<FrequencyDistribution[]>([]);
  const isTimeOut = ref<boolean>(false);
  const [isLoading, fetch] = useLoading(_fetch);

  const reload = async (fqThresholds?: number[]) => {
    const props = fqThresholds ? Object.assign(form, { fqThresholds }) : form;
    data.value = await fetch({ props, isTimeOut });
  };

  // NOTE: 初回のFQデータ読み込みはvueで実行

  return {
    isLoading,
    isTimeOut,
    data,
    reload
  };
};

const _fetch = async ({
  props,
  isTimeOut
}: {
  props: PropsFqData;
  isTimeOut: Ref<boolean>;
}): Promise<FrequencyDistribution[]> => {
  isTimeOut.value = false;
  try {
    const {
      data
    } = await TvDataSearchApi.getTvdataSearchCmReachAndFrequencyGraphDataSearch(
      props.companyId,
      props.startDate,
      props.endDate,
      props.startTime,
      props.endTime,
      props.areaCode,
      props.useCustomTarget,
      props.stations,
      props.isConversion15sec,
      props.viewingType,
      props.cmSponsorIds,
      props.cmProductIds,
      props.targetIds,
      props.buyingKind,
      props.cmDuration,
      props.cmCreativeIds,
      props.programSeriesIds,
      props.fqThresholds
    );
    return data;
  } catch (e) {
    if (e.status === httpCode.forbidden) {
      toast({
        title: 'FQデータの取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    if (e.status === httpCode.timeout) {
      toast({
        title: 'FQデータの取得がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
      isTimeOut.value = true;
    }
    return [] as FrequencyDistribution[];
  }
};
