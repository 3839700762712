import { CustomTargetApi } from '@/api';
import { CustomTarget } from '@/api/openapi';
import { TARGET_NAME, httpCode } from '@/common/constant';
import { toast } from '@/components/ui/Toast';
import {
  convertToTargetType,
  dsTargetType
} from '@/composables/datasearch/targets';
import useLoading from '@/composables/loading';
import { ComputedRef, Ref, computed, ref } from 'vue';

// ターゲット

interface CustomTargetListItem {
  id: number;
  label: string;
  isEnabled: boolean | undefined;
}
export interface Targets {
  customTargets: Ref<CustomTarget[]>;
  targetValue: Ref<string>;
  isCustomTarget: Ref<boolean>;
  targetType: Ref<dsTargetType>;
  basicTargetIds: Ref<number[]>;
  customTargetId: Ref<number[]>;
  customTargetList: ComputedRef<CustomTargetListItem[]>;
  initialTargetId: Ref<number[]>;
  isLoadingCustomTarget: Ref<boolean>;
  targetValid: Ref<string>;
  changeCustomTarget: (target: { name: string; value: { id: number } }) => void;
}
export const useTargets = (companyId: Ref<number>): Targets => {
  // カスタムターゲットの有無
  const targetValue = ref('個人全体・世帯');
  const customTargets = ref<CustomTarget[]>([]);
  const isCustomTarget = ref(false);
  const basicTargetIds = ref<number[]>([]);
  const customTargetId = ref<number[]>([]);
  const initialTargetId = ref<number[]>([]);
  // 性年齢12区分/男女10区分/カスタムターゲットの時に選択されたものが0
  const targetValid = ref<string>('');

  const [isLoadingCustomTarget, fetch] = useLoading(_fetch);

  (async () => {
    customTargets.value = await fetch(companyId);
  })();

  const customTargetList = computed(() => {
    return customTargets.value.map(({ id, name, isEnabled }) => ({
      id,
      label: name,
      isEnabled
    }));
  });
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeCustomTarget = (target: { name: string; value: any }) => {
    targetValue.value = target.name;
    // 個人全体の場合
    if (targetValue.value === TARGET_NAME.individualAndHousehold) {
      isCustomTarget.value = false;
      basicTargetIds.value = [];
      customTargetId.value = [];
      targetValid.value = '';
      return;
    }
    // カスタムターゲットの場合
    if (targetValue.value === TARGET_NAME.customTarget) {
      isCustomTarget.value = true;
      if (Array.isArray(target.value)) {
        if (target.value.length > 0) {
          // NOTE: multiSelectのバグ回避
          customTargetId.value = target.value.map(v =>
            v instanceof Object ? v.id : v
          );
        } else {
          customTargetId.value = [];
        }
      } else if (target.value) customTargetId.value = target.value.id;
      targetValid.value =
        customTargetId.value.length === 0
          ? 'いずれか一つは選択してください。'
          : '';
      return;
    }
    // 基本属性ターゲットの場合
    isCustomTarget.value = false;
    if (target.value) basicTargetIds.value = target.value as number[];
    targetValid.value =
      basicTargetIds.value.length === 0
        ? 'いずれか一つは選択してください。'
        : '';
  };

  const targetType = computed(() => {
    return convertToTargetType(targetValue.value);
  });

  return {
    isLoadingCustomTarget,
    targetValue,
    customTargets,
    isCustomTarget,
    targetType,
    basicTargetIds,
    customTargetId,
    customTargetList,
    initialTargetId,
    targetValid,
    changeCustomTarget
  };
};

const _fetch = async companyId => {
  try {
    // カスタムターゲット
    const { data } = await CustomTargetApi.getCompaniesCompanyIdCustomTargets(
      companyId.value,
      undefined,
      undefined
    );
    return data.list;
  } catch (e) {
    if (e.state === httpCode.forbidden) {
      toast({
        title: 'カスタムターゲットデータの取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    return [] as CustomTarget[];
  }
};
