import { format } from 'date-fns';

export const exportFileName = (
  v: string,
  startDate: string,
  endDate: string,
  companyId: string
): string => {
  if (startDate) {
    const start = format(new Date(startDate), 'yyyyMMdd');
    const end = format(new Date(endDate), 'yyyyMMdd');
    return `${v}_c${companyId}_${start}-${end}`;
  }
  return '';
};
