import { TvDataSearchApi } from '@/api';
import { AreaInfoIdEnum } from '@/api/openapi';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { useRouter } from 'vue-router';

import { httpCode } from '@/common/constant';
import { ROUTE_NAMES } from '@/router';

export interface CheckBoxGroup {
  label: string;
  id: string;
}

// OPTION: CM放送局
export interface TvStations {
  tvStationsValid: ComputedRef<string>;
  tvStations: Ref<CheckBoxGroup[]>;
  tvStationsValue: Ref<string[]>;
  isLoadingTvStations: Ref<boolean>;
  setTvStations: () => Promise<void>;
}

export const useTvStations = (
  areaValue: Ref<AreaInfoIdEnum | undefined>,
  companyId: Ref<number>
): TvStations => {
  const router = useRouter();

  const tvStations = ref<Array<CheckBoxGroup>>([]);
  const tvStationsValue = ref<Array<string>>([]); // model
  const tvStationsValid = computed(() => {
    if (tvStationsValue.value.length === 0) {
      return 'いずれか一つは選択してください。';
    } else {
      return '';
    }
  }); // valid

  const _change = async ({ areaValue, companyId }) => {
    tvStationsValue.value = [];
    tvStations.value = await _fetchTvStations(areaValue, companyId, router);
    tvStationsValue.value = tvStations.value.map(v => v.id);
  };
  const [isLoadingTvStations, change] = useLoading(_change);

  const setTvStations = async () => {
    await change({ areaValue, companyId });
  };

  return {
    tvStationsValid,
    tvStations,
    tvStationsValue,
    isLoadingTvStations,
    setTvStations
  };
};
const _fetchTvStations = async (
  areaValue,
  companyId,
  router
): Promise<CheckBoxGroup[]> => {
  try {
    const { data } = await TvDataSearchApi.getTvdataSearchStations(
      areaValue.value,
      companyId.value,
      true
    );
    // 整形
    const tvStations = [...data].map(v => ({
      id: v.stationCodes.join(','),
      label: v.displayStationName
    }));
    return tvStations;
  } catch (e) {
    if (e.status === httpCode.forbidden) {
      router.push({
        name: ROUTE_NAMES.error,
        params: {
          name: '権限がありません。',
          message:
            'アクセス先が存在しないか、アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
          back: undefined,
          status: '403/404 not found'
        },
        force: true
      });
    } else {
      toast({
        title: '放送局の取得に失敗しました。',
        variant: 'error'
      });
    }
    return [] as CheckBoxGroup[];
  }
};
