import { ref, Ref } from 'vue';
export interface RadioButtonGroup {
  label: string;
  value: string | number | undefined;
}

// OPTION: CM種別
export interface CmType {
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>;
  cmTypeOptions: RadioButtonGroup[];
}

const CM_TYPE = [
  { label: '全CM', value: undefined },
  { label: 'タイム', value: 'TIME' },
  { label: 'スポット', value: 'SPOT' }
];
export const useCmType = (): CmType => {
  const cmTypeValue = ref(undefined);
  const cmTypeOptions = CM_TYPE;
  return { cmTypeValue, cmTypeOptions };
};
