import { Ref, inject, ref } from 'vue';
import { useRoute } from 'vue-router';
import { TvDataSearchApi, CompanyApi } from '@/api';
import {
  CmReachAndFrequencyConditions,
  CmReachAndFrequencyTableColumn,
  CmReachAndFrequencyTableData,
  CompanySettings
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import { COMPANY_ROUTES } from '@/router';

import { PropsTableData } from '@/composables/datasearch/cmrf/inputform';

import useLoading from '@/composables/loading';

interface Cmrf {
  breadcrumbs: {
    parents: Array<{ name: string; label: string }>;
    current: { label: string };
  };
  companySettings: Ref<CompanySettings>;
  isCompanySettingLoading: Ref<boolean>;
  onSearch: (condition) => Promise<void>;
  isLoading: Ref<boolean>;
  isTimeOut: Ref<boolean>;
  isShow: Ref<boolean>;
  data: Ref<CmReachAndFrequencyTableColumn[]>;
  conditions: Ref<CmReachAndFrequencyConditions>;
  // eslint-disable-next-line
  papa: any;
}

const BREADCRUMBS = {
  parents: [
    { name: COMPANY_ROUTES.top, label: 'ホーム' },
    { name: '', label: 'TVデータサーチ' }
  ],
  current: { label: 'CM R&F' }
};

export const useCmrf = (): Cmrf => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $papa: any = inject('$papa');
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));

  const data = ref<CmReachAndFrequencyTableColumn[]>([]);
  const isShow = ref(false);
  const isTimeOut = ref<boolean>(false);
  const conditions = ref({} as CmReachAndFrequencyConditions);
  const companySettings = ref({} as CompanySettings);

  const [isLoading, fetch] = useLoading(_fetch);
  const [isCompanySettingLoading, fetchCompanySettings] = useLoading(
    _fetchCompanySettings
  );
  const onSearch = async (form: PropsTableData) => {
    isShow.value = true;
    const res = await fetch({ props: form, isTimeOut });
    data.value = res.tableData || [];
    conditions.value = res.conditions;
  };
  (async () => {
    companySettings.value = await fetchCompanySettings(companyId.value);
  })();

  return {
    breadcrumbs: BREADCRUMBS,
    isCompanySettingLoading,
    companySettings,
    onSearch,
    isLoading,
    isShow,
    isTimeOut,
    data,
    conditions,
    papa: $papa
  };
};

const _fetch = async ({
  props,
  isTimeOut
}: {
  props: PropsTableData;
  isTimeOut: Ref<boolean>;
}): Promise<CmReachAndFrequencyTableData> => {
  isTimeOut.value = false;

  try {
    const {
      data
    } = await TvDataSearchApi.getTvdataSearchCmReachAndFrequencyTableDataSearch(
      props.companyId,
      props.startDate,
      props.endDate,
      props.startTime,
      props.endTime,
      props.areaCode,
      props.targetType,
      props.stations,
      props.isConversion15sec,
      props.viewingType,
      props.cmSponsorIds,
      props.cmProductIds,
      props.targetIds,
      props.buyingKind,
      props.cmDuration,
      props.cmCreativeIds,
      props.programSeriesIds
    );

    return data;
  } catch (e) {
    handleError(e);
    if (e.status === httpCode.forbidden) {
      toast({
        title: 'データの取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    if (e.status === httpCode.timeout) {
      toast({
        title: 'データの取得がタイムアウトしました',
        message: e.message,
        variant: 'error'
      });
      isTimeOut.value = true;
    }
    return {} as CmReachAndFrequencyTableData;
  }
};

const _fetchCompanySettings = async (companyId): Promise<CompanySettings> => {
  try {
    const { data } = await CompanyApi.getCompaniesCompanyIdSettings(companyId);
    return data;
  } catch (e) {
    console.error(e);
    return {} as CompanySettings;
  }
};
