import { Ref } from 'vue';
import { TvDataSearchApi } from '@/api';
import { toast } from '@/components/ui/Toast';

import { TvDataSearchProgram } from '@/api/openapi';
import { requestCanceler } from '@/plugins/axiosCancel';

import { FormValue } from '@/composables/datasearch/cmrf/inputform';
import { formatDuration } from '../../../duration';
import { formatTvTimeLine } from './useTvTimeLine';

import useLoading from '@/composables/loading';

// OPTION: CM番組名
export interface TvPrograms {
  isLoadingTvPrograms: Ref<boolean>;
  fetchTvPrograms: (query: string) => Promise<unknown[]>;
}
export const useTvPrograms = ({ form }: { form: FormValue }): TvPrograms => {
  const {
    companyId,
    durationValue,
    tvStationsValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue
  } = form;
  const [isLoading, fetch] = useLoading(_fetch);

  // 番組名取得
  const fetchTvPrograms = async (search: string) => {
    if (
      tvStationsValue.value.length > 0 &&
      durationValue.value &&
      tvTimeLineStartValue.value &&
      tvTimeLineEndValue.value &&
      companyId.value
    ) {
      // APIのキャンセル
      requestCanceler.cancel();
      return await fetch({ form, search });
    } else {
      return await [];
    }
  };

  return {
    isLoadingTvPrograms: isLoading,
    fetchTvPrograms
  };
};

const _fetch = async ({ form, search }): Promise<TvDataSearchProgram[]> => {
  const {
    companyId,
    durationValue,
    tvStationsValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue
  } = form;

  const [startDate, endDate] = formatDuration(durationValue);
  const [startTime, endTime] = formatTvTimeLine(
    tvTimeLineStartValue,
    tvTimeLineEndValue
  );

  try {
    const {
      data
    } = await TvDataSearchApi.getTvdataSearchProgramsSearch(
      tvStationsValue.value,
      startDate,
      endDate,
      startTime,
      endTime,
      companyId.value,
      undefined,
      undefined,
      undefined,
      undefined,
      [],
      search,
      { cancelToken: requestCanceler.token() }
    );
    return (data as unknown) as TvDataSearchProgram[];
  } catch (e) {
    toast({ title: '失敗', message: e.message, variant: 'error' });
    return [];
  }
};
