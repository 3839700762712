/* eslint-disable @typescript-eslint/no-explicit-any */
import { TvDataSearchApi } from '@/api';
import {
  AreaInfoIdEnum,
  SelectableAreasList,
  TvDataSearchCmCreative,
  TvDataSearchCmrfHistory,
  TvDataSearchProduct,
  TvDataSearchCmrfHistoryViewingTypeEnum,
  CompanySettings
} from '@/api/openapi';
import { TARGET_NAME } from '@/common/constant';
import { DATE_FORMAT_SLASH, DATE_FORMAT_SLASH_AND_TIME } from '@/common/format';
import { toast } from '@/components/ui/Toast';
import {
  DataTabelType,
  useHistoryModal
} from '@/composables/datasearch/historymodal/historyModal';
import useLoading from '@/composables/loading';
import { format } from 'date-fns';
import { Ref, ref } from 'vue';
import { FormValue } from '../inputform';

// CM素材を選択
export interface History {
  isOpenModal: Ref<boolean>;
  historyData: Ref<any>;
  historyKey: Ref<number>;
  isFetchingHistory: Ref<boolean>;
  isDeletingHistory: Ref<boolean>;
  fetchHistory: (params: unknown) => Promise<void>;
  deleteHistory: (params: number) => Promise<boolean>;
  openHistoryModal: () => void;
  setHistory: (num: number) => Promise<void>;
}

interface cmCreativeDataListType extends TvDataSearchCmCreative {
  select: number;
}

export const useHistory = ({
  form,
  areaSelectOptions,
  cmCreativeDataListItems,
  isCmCreativeOptionsOpen,
  targetValue,
  initialTargetId,
  targetValid,
  setTvStations,
  isSubMenuOpen,
  isTotalOptions,
  companySettings
}: {
  form: FormValue;
  areaSelectOptions: Ref<SelectableAreasList[]>;
  cmCreativeDataListItems: Ref<cmCreativeDataListType[]>;
  isCmCreativeOptionsOpen: Ref<boolean>;
  targetValue: Ref<string>;
  initialTargetId: Ref<number[]>;
  targetValid: Ref<string>;
  setTvStations: () => Promise<void>;
  isSubMenuOpen: Ref<boolean>;
  isTotalOptions: Ref<boolean>;
  companySettings: CompanySettings;
}): History => {
  const {
    companyId,
    durationValue,
    areaValue,
    isCustomTarget,
    basicTargetIds,
    customTargetId,
    tvStationsValue,
    cmTypeValue,
    cmTimeValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    cmSponsorsValue,
    cmProductsValue,
    cmCreativeValue,
    tvProgramsValue,
    viewingTypeValue,
    isConversion15secValue
  } = form;

  const isOpenModal = ref(false);
  const baseHistoryData: Ref<TvDataSearchCmrfHistory[]> = ref([]);
  const historyData = ref();
  const historyKey = ref(0);
  const {
    convertToIsConversion15sec,
    convertToViewingType,
    convertToCmType,
    convertToCmDuration,
    convertToStationName,
    convertToTargetName,
    convertToAreaName
  } = useHistoryModal(companyId.value);

  const _fetchHistory = async () => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return;
      }
      const res = await TvDataSearchApi.getTvdataSearchCmReachAndFrequencyCompaniesCompanyIdHistories(
        companyId.value
      );
      if (200 <= res.status && res.status < 300) {
        baseHistoryData.value = res.data.list;
        historyData.value = convertToHistoryTableData(res.data.list);
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の取得に失敗しました',
        variant: 'error'
      });
    }
  };
  const [isFetchingHistory, fetchHistory] = useLoading(_fetchHistory);

  const _deleteHistory = async (args: number): Promise<boolean> => {
    try {
      if (!companyId.value) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        return false;
      }
      const res = await TvDataSearchApi.deleteTvdataSearchCmReachAndFrequencyHistoriesCmrfSearchHistoryId(
        args
      );
      if (200 <= res.status && res.status < 300) {
        return true;
      } else {
        toast({
          title: '失敗',
          message: '検索履歴の削除に失敗しました',
          variant: 'error'
        });
        return false;
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: '検索履歴の削除に失敗しました',
        variant: 'error'
      });
      return false;
    }
  };
  const [isDeletingHistory, deleteHistory] = useLoading(_deleteHistory);

  const convertToHistoryTableData = (
    dataList: TvDataSearchCmrfHistory[]
  ): DataTabelType[] => {
    const notSet = '指定なし';
    const deleteTarget = '削除されています';
    return dataList.map(data => {
      const today = new Date();
      const cmProductName =
        data.cmProducts?.map(v => v.cmProductName).join(',') || notSet;
      const cmSponsorName =
        data.cmSponsors?.map(v => v.cmSponsorName).join(',') || notSet;
      const targets =
        data.targets
          ?.map(v =>
            v.targetName
              ? v.targetName
              : !data.isCustomTargetNotDeleted
              ? deleteTarget
              : ''
          )
          .join(',') || TARGET_NAME.individualAndHousehold;
      const startDate = new Date(data.startDate.replace(/-/g, '/')) ?? today;
      const endDate = new Date(data.endDate.replace(/-/g, '/')) ?? today;
      const area = convertToAreaName(data.areaCode);
      const slash = data.searchedAt.replace(/-/g, '/');
      const dotIndex = slash.indexOf('.');
      const searchedAt = new Date(slash.slice(0, dotIndex)) ?? today;
      const baseConditions = {
        cmProductName: cmProductName,
        cmSponsorName: cmSponsorName,
        targets: targets,
        startDate: startDate,
        endDate: endDate,
        area: area,
        searchedAt: searchedAt,
        id: data.id,
        isCustomTargetNotDeleted: data.isCustomTargetNotDeleted,
        isProductUnchanged: data.isProductUnchanged
      };
      const cmCreatives =
        data.cmCreatives && data.cmCreatives.length > 0
          ? data.cmCreatives
              ?.map(v => v.cmSituation + '(' + v.cmCreativeId + ')')
              .join(',')
          : notSet;
      const allConditions = {
        ...baseConditions,
        searchedAt: format(searchedAt, DATE_FORMAT_SLASH_AND_TIME),
        cmCreatives: cmCreatives,
        buyingKind: convertToCmType(data.buyingKind?.toString()),
        viewingType: convertToViewingType(data.viewingType),
        isConversion15sec: convertToIsConversion15sec(data.isConversion15sec),
        cmDuration: convertToCmDuration(data.cmDuration),
        stations:
          convertToStationName(data.areaCode, data.stations).join(',') ||
          notSet,
        period:
          format(startDate, DATE_FORMAT_SLASH) +
          '〜' +
          format(endDate, DATE_FORMAT_SLASH),
        time: data.startTime + '〜' + data.endTime,
        programSeries:
          data.programSeries?.map(v => v.programName).join(',') || notSet
      };

      return { ...baseConditions, allConditions: allConditions };
    });
  };

  const openHistoryModal = () => {
    isOpenModal.value = true;
    fetchHistory('');
  };

  const setHistory = async (num: number) => {
    const history = baseHistoryData.value.find(v => v.id === num);
    if (!history) return;
    // 期間
    durationValue.value = {
      start: new Date(history.startDate.replace(/-/g, '/')),
      end: new Date(history.endDate.replace(/-/g, '/'))
    };
    // エリア
    areaValue.value = history.areaCode as AreaInfoIdEnum;
    await setTvStations();
    // 編集データのエリアが契約エリアに含まれているか判定
    const selectableAreaList = areaSelectOptions.value.flatMap(v =>
      v.areas.map(area => area.id)
    );
    if (!selectableAreaList.includes(areaValue.value ?? '')) {
      areaValue.value = undefined;
    }
    // 企業名
    if (cmSponsorsValue) {
      cmSponsorsValue.value = [];
      if (history.cmSponsors) {
        const tmpCmSponsorsValue: {
          id: number;
          name: string;
        }[] = [];
        history.cmSponsors.map(v => {
          tmpCmSponsorsValue.push({
            name: v.cmSponsorName ?? '',
            id: v.cmSponsorId ?? 0
          });
        });
        cmSponsorsValue.value = tmpCmSponsorsValue;
      }
    }
    // 商品／ブランド
    if (cmProductsValue) {
      cmProductsValue.value = [];
      if (history.cmProducts) {
        const tmpCmProductsValue: TvDataSearchProduct[] = [];
        history.cmProducts.map(v => {
          tmpCmProductsValue.push({
            id: v.cmProductId ?? 0,
            name: v.cmProductName ?? '',
            latestBroadcastDate: ''
          });
        });
        cmProductsValue.value = tmpCmProductsValue;
      }
    }
    // CM素材
    if (cmCreativeValue) {
      cmCreativeValue.value = [];
      if (history.cmCreatives && history.cmCreatives.length > 0) {
        isCmCreativeOptionsOpen.value = true;
        cmCreativeDataListItems.value = history.cmCreatives.map(v => ({
          id: v.cmCreativeId ?? 0,
          cmDuration: v.cmDuration ?? 0,
          cmSituation: v.cmSituation ?? '',
          cmTalent: v.cmTalent ?? '',
          cmBgm: v.cmBgm ?? '',
          cmRemarks: v.cmRemarks ?? '',
          select: v.cmCreativeId ?? 0
        }));
        cmCreativeValue.value = history.cmCreatives?.map(
          v => v.cmCreativeId ?? 0
        );
      } else {
        isCmCreativeOptionsOpen.value = false;
      }
    }
    // ターゲット
    basicTargetIds.value = [];
    customTargetId.value = [];
    targetValid.value = '';
    isCustomTarget.value = false;
    targetValue.value = convertToTargetName(history.targetType);
    if (history.targets) {
      if (targetValue.value === TARGET_NAME.customTarget) {
        customTargetId.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = customTargetId.value;
        isCustomTarget.value = true;
      } else if (targetValue.value === TARGET_NAME.individualAndHousehold) {
        isCustomTarget.value = false;
      } else {
        basicTargetIds.value = history.targets?.map(v => {
          return v.targetId ?? 0;
        });
        initialTargetId.value = basicTargetIds.value;
        isCustomTarget.value = false;
      }
    }
    // 視聴種別
    viewingTypeValue.value = isTotalOptions.value
      ? history.viewingType ?? undefined
      : TvDataSearchCmrfHistoryViewingTypeEnum.Realtime;
    // 15秒換算: nullの場合はカンパニー設定値を使用
    isConversion15secValue.value =
      history.isConversion15sec ?? companySettings.isConversion15sec;
    // CM種別
    cmTypeValue.value = history.buyingKind ?? undefined;
    // CM秒数
    cmTimeValue.value = history.cmDuration ?? undefined;
    // 放送局
    tvStationsValue.value = history.stations;
    // 番組名
    if (tvProgramsValue) {
      tvProgramsValue.value = [];
      if (history.programSeries) {
        const tmpTvProgramsValue: {
          programSeriesId: number;
          name: string;
        }[] = [];
        history.programSeries.map(v => {
          tmpTvProgramsValue.push({
            programSeriesId: v.programSeriesId ?? 0,
            name: v.programName ?? ''
          });
        });
        tvProgramsValue.value = tmpTvProgramsValue;
      }
    }
    // 時間帯
    tvTimeLineStartValue.value.HH = history.startTime.split(':')[0];
    tvTimeLineStartValue.value.mm = history.startTime.split(':')[1];
    tvTimeLineEndValue.value.HH = history.endTime.split(':')[0];
    tvTimeLineEndValue.value.mm = history.endTime.split(':')[1];

    isSubMenuOpen.value = true; // 検索オプションを開く
    historyKey.value += 1; // 画面表示更新
  };

  return {
    isOpenModal,
    historyData,
    historyKey,
    isFetchingHistory,
    isDeletingHistory,
    fetchHistory,
    deleteHistory,
    openHistoryModal,
    setHistory
  };
};
