<template>
  <Box class="brandlif-loading-message" text-align="center">
    <Typography size="s" color="primary" line-height="1.5" class="mt-6">
      集計に失敗しました。再読み込みしてください。
    </Typography>
    <Button
      width="140px"
      size="m"
      icon-name="reload"
      class="reload-btn mt-7"
      @click="$emit('reload')"
    >
      再読み込み
    </Button>
  </Box>
</template>
<script>
import { defineComponent } from 'vue';
import Box from '@/components/layout/Box.vue';
import Typography from '@/components/ui/Typography.vue';
import Button from '@/components/ui/Button.vue';
export default defineComponent({
  name: 'TimeoutScreen',
  components: {
    Box,
    Button,
    Typography
  }
});
</script>
<style lang="scss">
.brandlif-loading-message {
  .ui-typography.primary,
  .loading {
    color: var(--chart-blue-2);
  }
}
.reload-btn {
  background: hsl(223, 64%, 98%) !important;
  color: #6b90df !important;
  border: 0;
  box-shadow: none;
}
</style>
