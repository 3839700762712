import { ref, Ref, watch } from 'vue';

import {
  TvDataSearchCmrfHistoryViewingTypeEnum,
  CompanySettings
} from '@/api/openapi';

export interface RadioButtonGroup {
  label: string;
  value: string | number | undefined;
}

// 視聴種別
export interface ViewingType {
  viewingTypeValue: Ref<TvDataSearchCmrfHistoryViewingTypeEnum>;
  viewingTypeOptions: RadioButtonGroup[];
}

const VIEW_TYPE = [
  {
    label: 'リアルタイム',
    value: TvDataSearchCmrfHistoryViewingTypeEnum.Realtime
  },
  { label: 'トータル', value: TvDataSearchCmrfHistoryViewingTypeEnum.Total }
];

export const useViewingType = ({
  companySettings,
  isTotalOptions
}: {
  companySettings: CompanySettings;
  isTotalOptions: Ref<boolean>;
}): ViewingType => {
  const viewingTypeValue = ref(
    isTotalOptions.value
      ? ((companySettings.viewingType as unknown) as TvDataSearchCmrfHistoryViewingTypeEnum)
      : TvDataSearchCmrfHistoryViewingTypeEnum.Realtime
  );

  // 基本設定
  watch([isTotalOptions], () => {
    // 権限が有効の場合はCompany設定、無効の場合はリアルタイムに設定
    viewingTypeValue.value = isTotalOptions.value
      ? viewingTypeValue.value
      : TvDataSearchCmrfHistoryViewingTypeEnum.Realtime;
  });

  const viewingTypeOptions = VIEW_TYPE;
  // 視聴種別による期間のmin値設定はuseDurationで行う
  return { viewingTypeValue, viewingTypeOptions };
};
