import { ref, computed, ComputedRef, Ref } from 'vue';
// OPTION: 時間帯
export interface TvTimeLineRange {
  HH: string;
  mm: string;
}
export interface TvTimeLine {
  tvTimeLineValid: ComputedRef<string>;
  tvTimeLineStartValue: Ref<TvTimeLineRange>;
  tvTimeLineEndValue: Ref<TvTimeLineRange>;
  tvTimeLineSelectOptions: number[];
}
export const useTvTimeLine = (): TvTimeLine => {
  const tvTimeLineStartValue = ref({
    HH: '05',
    mm: '00'
  }); //model
  const tvTimeLineEndValue = ref({
    HH: '28',
    mm: '59'
  }); //model
  const tvTimeLineValid = computed(() => {
    if (
      tvTimeLineStartValue.value.HH &&
      tvTimeLineStartValue.value.mm &&
      tvTimeLineEndValue.value.HH &&
      tvTimeLineEndValue.value.mm
    ) {
      if (
        Object.values(tvTimeLineStartValue.value).join(':') >=
        Object.values(tvTimeLineEndValue.value).join(':')
      ) {
        return '終了時間は開始時間より後にしてください。';
      } else {
        return '';
      }
    } else {
      return '時刻を入力してください。';
    }
  });

  const tvTimeLineSelectOptions = Array.from(Array(24).keys(), x => x + 5);

  return {
    tvTimeLineValid,
    tvTimeLineStartValue,
    tvTimeLineSelectOptions,
    tvTimeLineEndValue
  };
};

export const formatTvTimeLine = (
  tvTimeLineStartValue: Ref<TvTimeLineRange>,
  tvTimeLineEndValue: Ref<TvTimeLineRange>
): string[] => {
  if (tvTimeLineStartValue && tvTimeLineStartValue.value) {
    return [
      Object.values(tvTimeLineStartValue.value).join(':'),
      Object.values(tvTimeLineEndValue.value).join(':')
    ];
  }
  return [];
};
