import { ref, Ref, watch } from 'vue';

import { CompanySettings } from '@/api/openapi';

interface RadioButtonGroup {
  label: string;
  value: boolean | undefined;
}

// 視聴種別
export interface IsConversion15sec {
  isConversion15secValue: Ref<boolean>;
  isConversion15secOptions: Ref<RadioButtonGroup[]>;
  isConversion15secDisabled: Ref<boolean>;
}

const TYPE = [
  { label: 'する', value: true },
  { label: 'しない', value: false }
];

export const useIsConversion15sec = (
  companySetting: CompanySettings,
  cmTimeValue: Ref<number | undefined>
): IsConversion15sec => {
  const isConversion15secValue = ref(companySetting.isConversion15sec);
  const isConversion15secDisabled = ref(false);
  const isConversion15secOptions = ref(TYPE);

  // CM秒数が15秒の場合はCM15秒換算を「する」に固定し、選択不可にする
  watch(cmTimeValue, v => {
    isConversion15secDisabled.value = v === 15;
    if (v === 15) {
      isConversion15secValue.value = true;
    }
  });
  // 視聴種別による期間のmin値設定はuseDurationで行う
  return {
    isConversion15secValue,
    isConversion15secOptions,
    isConversion15secDisabled
  };
};
