import { ComputedRef, Ref, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import {
  AreaInfoIdEnum,
  TvDataSearchCmSponsor,
  TvDataSearchProduct,
  TvDataSearchProgram,
  TvDataSearchCmrfHistoryViewingTypeEnum,
  CompanySettings,
  CmCategory
} from '@/api/openapi';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { formatDuration, DataType } from '@/composables/duration';
import { formatTvTimeLine } from '@/composables/datasearch/cmrf/inputs/useTvTimeLine';
import {
  useArea,
  useViewingType,
  useIsConversion15sec,
  useCmCreative,
  useCmProducts,
  useCmSponsors,
  useCmTime,
  useCmType,
  useDuration,
  useHistory,
  useTargets,
  useTvPrograms,
  useTvStations,
  useTvTimeLine,
  useGetFeatureOptions
} from './inputs';
import { TvTimeLineRange } from './inputs/useTvTimeLine';

export interface Ids {
  cmSponsorsValue: Ref<TvDataSearchCmSponsor[]> | undefined;
  cmProductsValue: Ref<TvDataSearchProduct[]> | undefined;
  cmCreativeValue: Ref<number[]> | undefined;
  tvProgramsValue: Ref<TvDataSearchProgram[]> | undefined;
}
export interface FormValue extends Ids {
  companyId: Ref<number>;
  durationValue: Ref<DateRange | undefined>;
  areaValue: Ref<AreaInfoIdEnum | undefined>;
  isCustomTarget: Ref<boolean>;
  targetType: Ref<dsTargetType>;
  viewingTypeValue: Ref<TvDataSearchCmrfHistoryViewingTypeEnum>;
  cmCategoriesValue: Ref<CmCategory[]>;
  isConversion15secValue: Ref<boolean>;
  basicTargetIds: Ref<number[]>;
  customTargetId: Ref<number[]>;
  tvStationsValue: Ref<string[]>;
  cmTypeValue: Ref<'TIME' | 'SPOT' | undefined>;
  cmTimeValue: Ref<number | undefined>;
  tvTimeLineStartValue: Ref<TvTimeLineRange>;
  tvTimeLineEndValue: Ref<TvTimeLineRange>;
}

import { Area } from '@/composables/datasearch/cmrf/inputs/useArea';
import { CmCreative } from '@/composables/datasearch/cmrf/inputs/useCmCreative';
import { CmProducts } from '@/composables/datasearch/cmrf/inputs/useCmProducts';
import { CmSponsors } from '@/composables/datasearch/cmrf/inputs/useCmSponsors';
import { CmTime } from '@/composables/datasearch/cmrf/inputs/useCmTime';
import { CmType } from '@/composables/datasearch/cmrf/inputs/useCmType';
import { useDurationType } from '@/composables/duration';
import { History } from '@/composables/datasearch/cmrf/inputs/useHistory';
import { Targets } from '@/composables/datasearch/cmrf/inputs/useTargets';
import { TvPrograms } from '@/composables/datasearch/cmrf/inputs/useTvPrograms';
import { TvStations } from '@/composables/datasearch/cmrf/inputs/useTvStations';
import { TvTimeLine } from '@/composables/datasearch/cmrf/inputs/useTvTimeLine';
import { ViewingType } from '@/composables/datasearch/cmrf/inputs/useViewingType';
import { IsConversion15sec } from '@/composables/datasearch/cmrf/inputs/useIsConversion15sec';
import { GetFeatureOptions } from '@/composables/datasearch/cmrf/inputs/useFeatureOptions';

import { dsTargetType } from '../targets';

export interface CmrfInputForm
  extends useDurationType,
    Area,
    ViewingType,
    CmSponsors,
    CmProducts,
    CmCreative,
    CmType,
    CmTime,
    IsConversion15sec,
    TvStations,
    TvPrograms,
    TvTimeLine,
    Targets,
    History,
    Ids,
    GetFeatureOptions {
  form: FormValue;
  isSubMenuOpen: Ref<boolean>;
  isDisabled: Ref<boolean>;
}
export interface PropsTableData {
  companyId: number;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  areaCode: string;
  useCustomTarget: boolean;
  targetType: dsTargetType;
  stations: string[];
  isConversion15sec: boolean;
  viewingType: TvDataSearchCmrfHistoryViewingTypeEnum;
  cmSponsorIds?: number[] | undefined;
  cmProductIds?: number[] | undefined;
  targetIds?: number[] | undefined;
  buyingKind?: string | undefined;
  cmDuration?: number | undefined;
  cmCreativeIds?: number[] | undefined;
  programSeriesIds?: number[] | undefined;
}

interface FormatIdsProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: any;
  key: string;
}

export const useCmrfInputForm = ({
  companySettings
}: {
  companySettings: CompanySettings;
}): CmrfInputForm => {
  const route = useRoute();
  const { params } = route;
  const companyId = ref(Number(params.companyId));
  const isSubMenuOpen = ref(false);

  // 権限: FeatureOptions
  const {
    getFeatureOptions,
    isTotalOptions,
    ...featureOptions
  } = useGetFeatureOptions(route);

  getFeatureOptions();

  // エリア
  const {
    areaValid,
    areaValue,
    areaOptionStore,
    areaSelectOptions,
    getArea,
    ...area
  } = useArea();
  // 視聴種別
  const { viewingTypeValue, ...viewingType } = useViewingType({
    companySettings,
    isTotalOptions
  });
  // 期間
  const { durationValid, durationRef, initDuration, ...duration } = useDuration(
    areaValue,
    DataType.CM,
    viewingTypeValue
  );
  // CM種別
  const { cmTypeValue, ...cmType } = useCmType();
  // CM秒数
  const { cmTimeValue, ...cmTime } = useCmTime();
  // 15秒換算
  const { isConversion15secValue, ...isConversion15sec } = useIsConversion15sec(
    companySettings,
    cmTimeValue
  );
  // 放送局
  const {
    tvStationsValid,
    tvStationsValue,
    setTvStations,
    ...tvStations
  } = useTvStations(areaValue, companyId);
  // 時間帯
  const {
    tvTimeLineValid,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    ...tvTimeLine
  } = useTvTimeLine();
  // ターゲット
  const {
    targetValue,
    basicTargetIds,
    customTargetId,
    initialTargetId,
    isCustomTarget,
    targetType,
    targetValid,
    ...target
  } = useTargets(companyId);

  // 検索用
  const cmSponsorsValue = ref([] as TvDataSearchCmSponsor[]);
  const cmProductsValue = ref([] as TvDataSearchProduct[]);
  const cmCreativeValue = ref([] as number[]);
  const tvProgramsValue = ref([] as TvDataSearchProgram[]);
  const cmCategoriesValue = ref([] as CmCategory[]);

  const form: FormValue = {
    companyId,
    durationValue: durationRef,
    areaValue,
    isCustomTarget,
    targetType,
    basicTargetIds,
    customTargetId,
    tvStationsValue,
    viewingTypeValue,
    isConversion15secValue,
    cmTypeValue,
    cmCategoriesValue,
    cmTimeValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    cmSponsorsValue,
    cmProductsValue,
    cmCreativeValue,
    tvProgramsValue
  };

  // 企業名(CMスポンサー)
  const { ...cmSponsors } = useCmSponsors({ form });
  // 商品／ブランド
  const { ...cmProducts } = useCmProducts({ form });
  // 番組名
  const { ...tvPrograms } = useTvPrograms({ form });
  // CM素材
  const {
    cmCreativeDataListItems,
    isCmCreativeOptionsOpen,
    ...cmCreative
  } = useCmCreative({
    form,
    durationValue: durationRef,
    cmCreativeValue
  });

  // 検索履歴
  const { ...history } = useHistory({
    form,
    areaSelectOptions,
    cmCreativeDataListItems,
    isCmCreativeOptionsOpen,
    targetValue,
    initialTargetId,
    targetValid,
    setTvStations,
    isSubMenuOpen,
    isTotalOptions,
    companySettings
  });

  // バリデーション
  const { isDisabled } = useValidator({
    durationValid,
    areaValid,
    cmSponsorsValue,
    cmProductsValue,
    tvProgramsValue,
    targetValid,
    tvTimeLineValid,
    tvStationsValid
  });

  // init
  (async () => {
    await getArea();
    await initDuration();
    await setTvStations();
  })();

  return {
    durationValid,
    durationRef,
    initDuration,
    ...duration,
    //
    areaValid,
    areaValue,
    areaOptionStore,
    areaSelectOptions,
    getArea,
    ...area,
    //
    viewingTypeValue,
    ...viewingType,
    //
    isConversion15secValue,
    ...isConversion15sec,
    //
    cmSponsorsValue,
    ...cmSponsors,
    //
    cmProductsValue,
    ...cmProducts,
    //
    cmCreativeValue,
    cmCreativeDataListItems,
    isCmCreativeOptionsOpen,
    ...cmCreative,
    //
    targetValue,
    basicTargetIds,
    customTargetId,
    initialTargetId,
    isCustomTarget,
    targetType,
    targetValid,
    ...target,
    //
    cmTypeValue,
    ...cmType,
    //
    cmTimeValue,
    ...cmTime,
    //
    tvStationsValid,
    tvStationsValue,
    setTvStations,
    ...tvStations,
    //
    tvProgramsValue,
    ...tvPrograms,
    //
    tvTimeLineValid,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    ...tvTimeLine,
    //
    ...history,
    //
    form,
    getFeatureOptions,
    isTotalOptions,
    ...featureOptions,
    isSubMenuOpen,
    isDisabled
  };
};

export const formatFrom = (form: FormValue): PropsTableData => {
  const {
    companyId,
    areaValue,
    viewingTypeValue,
    isConversion15secValue,
    tvStationsValue,
    cmTypeValue,
    cmTimeValue,
    cmSponsorsValue,
    cmProductsValue,
    tvProgramsValue,
    cmCreativeValue,
    durationValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    isCustomTarget,
    targetType,
    customTargetId,
    basicTargetIds
  } = form;

  const [cmProductIds, programSeriesIds, cmSponsorIds] = formatIds([
    { items: cmProductsValue, key: 'id' },
    { items: tvProgramsValue, key: 'programSeriesId' },
    { items: cmSponsorsValue, key: 'id' }
  ]);
  const [startDate, endDate] = formatDuration(durationValue);
  const [startTime, endTime] = formatTvTimeLine(
    tvTimeLineStartValue,
    tvTimeLineEndValue
  );
  const targetIds = computed(() =>
    isCustomTarget.value ? customTargetId.value : basicTargetIds.value
  );

  const stations = computed(() =>
    tvStationsValue.value.map(v => (v.indexOf(',') ? v.split(',') : v)).flat()
  );

  return {
    companyId: companyId.value,
    startDate,
    endDate,
    startTime,
    endTime,
    areaCode: areaValue.value ?? '',
    useCustomTarget: isCustomTarget.value,
    targetType: targetType.value,
    stations: stations.value,
    isConversion15sec: isConversion15secValue.value,
    viewingType: viewingTypeValue.value,
    cmSponsorIds: cmSponsorIds.value,
    cmProductIds: cmProductIds.value,
    targetIds: targetIds.value,
    buyingKind: cmTypeValue.value,
    cmDuration: cmTimeValue.value,
    cmCreativeIds: cmCreativeValue ? cmCreativeValue.value : undefined,
    programSeriesIds: programSeriesIds.value
  };
};

// refからidを返す
export const formatIds = (
  props: Array<FormatIdsProp | undefined>
): ComputedRef<number[]>[] => {
  const res = props.map(e => {
    return computed(() => {
      if (e && e.items && e.items.value) {
        return e.items.value.map(val => val[e.key]);
      } else {
        return [];
      }
    });
  });

  return res;
};

// NOTE: TODO: もうちょっと良い書き方がありそう
const useValidator = props => {
  const {
    durationValid,
    areaValid,
    cmSponsorsValue,
    cmProductsValue,
    tvProgramsValue,
    targetValid,
    tvTimeLineValid,
    tvStationsValid
  } = props;
  const isDisabled = ref(true);
  const valids = ref({
    duration: false, // 初期値ある
    area: false,
    cmSponsorsAndProductsAndPrograms: true,
    target: false, // 初期値が個人全体のため
    tvStations: false, // 初期値ある
    tvTimeLine: false // 初期値ある
  });
  watch(areaValid, () => {
    valids.value.area = Boolean(areaValid.value);
  }); // エリア
  watch([cmSponsorsValue, cmProductsValue, tvProgramsValue], v => {
    valids.value.cmSponsorsAndProductsAndPrograms = !v.some(j => j.length > 0);
  }); // 企業名&商品／ブランドバリデーション
  watch(targetValid, () => {
    valids.value.target = Boolean(targetValid.value);
  }); // ターゲット
  watch(durationValid, () => {
    valids.value.duration = Boolean(durationValid.value);
  }); // 期間
  watch(tvStationsValid, () => {
    valids.value.tvStations = Boolean(tvStationsValid.value);
  }); // 放送局
  watch(tvTimeLineValid, () => {
    valids.value.tvTimeLine = Boolean(tvTimeLineValid.value);
  }); // タイムライン
  watch(
    valids,
    v => {
      isDisabled.value = Object.values(v).includes(true);
    },
    { deep: true }
  ); //バリデーションまとめ
  return {
    isDisabled
  };
};
