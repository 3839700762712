import { Ref, ref } from 'vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { useUserInfoStore } from '@/store/userInfo';
import { WorkspaceFeatureOptionTypeEnum } from '@/api/openapi';

export interface GetFeatureOptions {
  getFeatureOptions: () => void;
  isTotalOptions: Ref<boolean>;
}

export const useGetFeatureOptions = (
  route: RouteLocationNormalizedLoaded
): GetFeatureOptions => {
  const isTotalOptions = ref(false);

  const getFeatureOptions = () => {
    // 権限: トータルオプション
    const userInfoStore = useUserInfoStore();
    const currentWorkspace = userInfoStore.currentWorkspaceFromRoute(route);
    const featureOptionViewingTypeTotal = currentWorkspace?.featureOptions.find(
      v => v.type === WorkspaceFeatureOptionTypeEnum.ViewingTypeTotal
    );
    // FeatureOptionが存在しないか、statusがENABLEDの場合はtrueそれ以外はfalse
    isTotalOptions.value = featureOptionViewingTypeTotal?.status === 'ENABLED';
  };

  return {
    getFeatureOptions,
    isTotalOptions
  };
};
