import { ref, Ref } from 'vue';
export interface RadioButtonGroup {
  label: string;
  value: string | number | undefined;
}

// OPTION: CM秒数
export interface CmTime {
  cmTimeValue: Ref<number | undefined>;
  cmTimeOptions: RadioButtonGroup[];
}

const CM_TIMES = [
  { label: '全CM', value: undefined },
  { label: '15秒', value: 15 },
  { label: '30秒以上', value: 30 }
];
export const useCmTime = (): CmTime => {
  const cmTimeValue = ref(undefined);
  const cmTimeOptions = CM_TIMES;
  return { cmTimeValue, cmTimeOptions };
};
