import { Ref } from 'vue';
import { TvDataSearchApi } from '@/api';
import { TvDataSearchCmSponsor } from '@/api/openapi';
import { formatIds, FormValue } from '@/composables/datasearch/cmrf/inputform';

import { toast } from '@/components/ui/Toast';
import { httpCode } from '@/common/constant';
import { requestCanceler } from '@/plugins/axiosCancel';

import { formatDuration } from '../../../duration';
import { formatTvTimeLine } from './useTvTimeLine';

import useLoading from '@/composables/loading';

// 企業名
export interface CmSponsors {
  isLoadingCmSponsors: Ref<boolean>;
  fetchCmSponsors: (query: string) => Promise<unknown[]>;
}

export const useCmSponsors = ({ form }: { form: FormValue }): CmSponsors => {
  const {
    tvStationsValue,
    durationValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    companyId
  } = form;
  const [isLoading, fetch] = useLoading(_fetch);
  // 企業名取得
  const fetchCmSponsors = async (search: string) => {
    if (
      tvStationsValue.value.length > 0 &&
      durationValue.value &&
      tvTimeLineStartValue.value &&
      tvTimeLineEndValue.value &&
      companyId.value
    ) {
      // APIのキャンセル
      requestCanceler.cancel();
      return await fetch({ form, search });
    } else {
      return await [];
    }
  };

  return {
    isLoadingCmSponsors: isLoading,
    fetchCmSponsors
  };
};

const _fetch = async ({ form, search }): Promise<TvDataSearchCmSponsor[]> => {
  const {
    companyId,
    durationValue,
    tvStationsValue,
    cmTypeValue,
    cmTimeValue,
    tvTimeLineStartValue,
    tvTimeLineEndValue,
    cmSponsorsValue,
    cmProductsValue,
    tvProgramsValue
  } = form;
  const [productsIds, programsIds, sponsorsIds] = formatIds([
    { items: cmProductsValue, key: 'id' },
    { items: tvProgramsValue, key: 'programSeriesId' },
    { items: cmSponsorsValue, key: 'id' }
  ]);

  const [startDate, endDate] = formatDuration(durationValue);
  const [startTime, endTime] = formatTvTimeLine(
    tvTimeLineStartValue,
    tvTimeLineEndValue
  );
  try {
    const {
      data
    } = await TvDataSearchApi.getTvdataSearchCmSponsorsSearch(
      tvStationsValue.value,
      startDate,
      endDate,
      startTime,
      endTime,
      companyId.value,
      cmTimeValue.value,
      cmTypeValue.value,
      sponsorsIds.value,
      productsIds.value,
      programsIds.value,
      search,
      { cancelToken: requestCanceler.token() }
    );
    return data;
  } catch (e) {
    if (e.state === httpCode.forbidden) {
      toast({
        title: '企業データの取得に失敗しました',
        message: e.message,
        variant: 'error'
      });
    }
    return [] as TvDataSearchCmSponsor[];
  }
};
