import { ref, Ref } from 'vue';
import useLoading from '@/composables/loading';
import { toast } from '@/components/ui/Toast';
import { round } from '@/common/formatter';
import { PropsTableData } from '@/composables/datasearch/cmrf/inputform';

import {
  CmReachAndFrequencyTableColumn,
  CmReachAndFrequencyConditions,
  FrequencyDistribution
} from '@/api/openapi';
import { TvDataSearchApi } from '@/api';

import saveAs from 'file-saver';

interface Header {
  id: string;
  label: string;
}
const HEADERS: Header[] = [
  { id: 'targetName', label: '' },
  { id: 'reachNumOfPeople', label: 'リーチ人数(人)' },
  { id: 'reachRate', label: 'リーチ率(%)' },
  { id: 'grp', label: 'GRP' },
  { id: 'averageFrequency', label: '平均フリークエンシー(回)' }
];

/**
 * CSV_OPTIONSはv12.0の段階では表装に表示しない
 */
export enum SeparationType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  CM = 'CM'
}

enum AggregationType {
  ALL = 'ALL',
  CM_SPONSOR = 'CM_SPONSOR',
  PRODUCT = 'PRODUCT',
  CM_CREATIVE = 'CM_CREATIVE'
}

enum stationType {
  ALL = 'ALL',
  STATION = 'STATION'
}

export const CSV_OPTIONS = [
  {
    label: '期間区切り',
    id: 'period',
    options: [
      {
        label: '日別',
        value: 'DAY'
      },
      {
        label: '週別',
        value: 'WEEK'
      },
      {
        label: '月別',
        value: 'MONTH'
      },
      {
        label: 'CM別',
        value: 'CM'
      }
    ]
  },
  {
    label: '集計軸',
    id: 'type',
    options: [
      {
        label: 'すべて',
        value: 'ALL'
      },
      {
        label: '企業別',
        value: 'CM_SPONSOR'
      },
      {
        label: '商品別',
        value: 'CM_PRODUCT'
      }
    ]
  },
  {
    label: '放送局',
    id: 'tvStations',
    options: [
      {
        label: 'すべて',
        value: 'ALL'
      },
      {
        label: '放送局別',
        value: 'STATION'
      }
    ]
  }
];

interface Option {
  label: string;
  value: string;
}

interface Item {
  label: string;
  id: string;
  options: Option[];
}

interface CmrfCsv {
  items: Item[];
  csvOptions: Ref<{
    separationType: SeparationType;
    aggregationType: AggregationType;
    stationType: stationType;
  }>;
  isLoading: Ref<boolean>;
  submit: (options: SubmitOptions) => Promise<void>;
}

interface SubmitOptions extends PropsTableData {
  separationType: SeparationType;
  aggregationType: AggregationType;
  stationType: stationType;
}

export const useCmrfCsv = (): CmrfCsv => {
  const csvOptions = ref({
    separationType: SeparationType.DAY,
    aggregationType: AggregationType.ALL,
    stationType: stationType.ALL
  });
  const [isLoading, submit] = useLoading(_submit);

  return {
    items: CSV_OPTIONS,
    csvOptions,
    isLoading,
    submit
  };
};

/**
 * グラフCSVの作成
 * Server側で生成したグラフbolbからCSVを作成
 * @param options
 */
const _submit = async (options: SubmitOptions): Promise<void> => {
  try {
    const res = await TvDataSearchApi.getTvdataSearchCmReachAndFrequencyCsv(
      options.companyId,
      options.startDate,
      options.endDate,
      options.startTime,
      options.endTime,
      options.areaCode,
      options.useCustomTarget,
      options.stations,
      options.separationType,
      options.aggregationType,
      options.stationType,
      options.isConversion15sec,
      options.viewingType,
      options.cmSponsorIds,
      options.cmProductIds,
      options.targetIds,
      options.buyingKind,
      options.cmDuration,
      options.cmCreativeIds,
      options.programSeriesIds
    );
    // CSV SAVE
    if (res) {
      const mineType = res.headers['content-type'];
      const name = res.headers['content-disposition'];
      const reg = RegExp(/filename=(.*)/);
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
        type: mineType
      });
      saveAs(blob, name.match(reg)[1]);
    }
  } catch (e) {
    toast({
      title: 'CSVダウンロード失敗',
      message: e.message,
      variant: 'error'
    });
  }
};

// CSV
const createSampleConditionTexts = (
  title: string,
  details: CmReachAndFrequencyConditions
): string[][] => {
  const sampleCondition: string[][] = [];
  sampleCondition.push([title]);
  sampleCondition.push(['期間:', `${details.period}`]);
  sampleCondition.push(['時間帯:', `${details.timeZone}`]);
  sampleCondition.push(['エリア:', details.areaName]);
  sampleCondition.push(['期間内有効サンプルサイズ:', ...details.sampleSize]);
  sampleCondition.push(['企業名:', details.cmSponsorNames]);
  sampleCondition.push(['商品／ブランド名:', details.cmProductNames]);
  sampleCondition.push(['CM素材別:', details.cmCreativeNames]);
  sampleCondition.push(['CM種別:', details.cmType]);
  sampleCondition.push(['CM秒数:', details.cmDuration]);
  sampleCondition.push(['放送局:', details.stations]);
  sampleCondition.push(['番組名:', details.programs]);
  sampleCondition.push(['視聴種別:', details.viewingType]);
  sampleCondition.push(['利用データ:', details.reportType]);
  sampleCondition.push(['15秒換算:', details.convertIn15Seconds]);
  sampleCondition.push(['作成日時:', details.createdAt]);
  sampleCondition.push(['データ提供元:', 'Switch Media, Inc.']);
  return sampleCondition;
};
const outputCsv = (
  filename: string,
  sampleCondition: string[][],
  body: string[][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const csvData: string[][] = [];
  csvData.push(...sampleCondition);
  csvData.push([]);
  csvData.push(...body);

  const link = document.createElement('a');
  const blob = new Blob(
    [
      new Uint8Array([0xef, 0xbb, 0xbf]),
      $papa.unparse({
        data: csvData
      })
    ],
    {
      type: 'text/csv'
    }
  );

  link.setAttribute('download', filename);
  link.setAttribute('href', window.webkitURL.createObjectURL(blob));
  link.click();
};
export const outputFrequencyCsv = (
  filename: string,
  title: string,
  graphData: CmReachAndFrequencyTableColumn[],
  details: CmReachAndFrequencyConditions,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(title, details);

  // body
  const head: { label: string; id: string }[] = HEADERS.map(v => ({
    id: v.id,
    label: `${v.label}`
  }));
  const tableBody = head.map(v => [
    v.label,
    ...graphData.map(e => {
      const value = e[v.id];
      switch (v.id) {
        case 'reachNumOfPeople':
          return value == null ? '-' : round(value, 1).toString();
        default:
          return round(value, 7).toString();
      }
    })
  ]);

  outputCsv(filename, sampleConditionTexts, tableBody, $papa);
};
export const outputGraphCsv = (
  filename: string,
  title: string,
  graphData: FrequencyDistribution[],
  details: CmReachAndFrequencyConditions,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  $papa: any
): void => {
  const sampleConditionTexts = createSampleConditionTexts(title, details);
  // theadの作成
  const newGraphData = graphData.slice(0, -1); //世帯を抜いたデータ
  const last = graphData.slice(-1)[0] || ({} as FrequencyDistribution); //世帯だけのデータ
  const targetNames = newGraphData.map(v => v.displayName);
  const empty = new Array(targetNames.length).fill('');
  const fqPeoples = [...empty];
  fqPeoples[0] = 'フリークエンシー区分別人数（人）';
  const fqParcents = [...empty];
  fqParcents[0] = 'フリークエンシー区分別割合（%）';
  const headers = [
    ['', ...fqPeoples, ...fqParcents],
    ['フリークエンシー区分', ...targetNames, ...targetNames, last.displayName]
  ];
  // tbodyの作成
  // 縦横変換
  const transpose = a => a[0].map((_, c) => a.map(r => r[c]));
  // 人数
  const numOfPeoples = newGraphData.map(v =>
    v.frequencyRatio.map(j =>
      j.frequencyNumOfPeople == null ? '' : round(j.frequencyNumOfPeople, 1)
    )
  );
  const transNum = transpose(numOfPeoples);
  // 割合
  const rates = newGraphData.map(v =>
    v.frequencyRatio.map(j => round(j.frequencyRate, 7))
  );
  const transRates = transpose(rates);
  // 世帯
  const lasts = [last.frequencyRatio.map(j => round(j.frequencyRate, 7))];
  const transLasts = transpose(lasts);
  // th作成
  const fqThresholds = newGraphData[0].frequencyRatio.map(j => j.fqThreshold);
  const labels = fqThresholds.map((v, i) => {
    switch (i) {
      case 0: // 最初
        return `${v}回`;
      case fqThresholds.length - 1: // 最後
        return `${v}回以上`;
      default:
        return `${v}回〜${fqThresholds[i + 1] - 1}回`;
    }
  });
  const tbody = labels.map((v, i) => [
    v,
    ...transNum[i],
    ...transRates[i],
    ...transLasts[i]
  ]);
  // body
  const tableBody = [...headers, ...tbody];

  outputCsv(filename, sampleConditionTexts, tableBody, $papa);
};
